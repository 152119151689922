.sectionContainer {
	width: 70%;
	text-align: center;
	padding-left: 15%;
}

.header {
	color: white;
	font-size: 30px;
	padding-top: 20px;
}

.overviewSection {
	width: 60%;
	padding-left: 20%;
}

.overviewText {
	color: white;
	text-align: left;
	padding: 10px;
}

.featureSection {
	width: 70%;
	margin-left: 15%;
	height: 325px;
	margin-top: 20px;
	background-color: #3f51a1;
	border-radius: 25px;
	float: left;
}

.featureTitle {
	font-size: 25px;
	color: white;
	width: 100%;
	background-color: #333537;
	border-radius: 25px 25px 0px 0px;
	display: inline-block;
}

.featureSectionColumnOne {
	width: 30%;
	float: left;
}
.featureSectionColumnTwo {
	text-align: left;
	padding-top: 10px;
	color: white;
	div {
		padding-bottom: 5px;
	}
}
