.navbarContainer {
	background-color: #333537;
	height: 75px;
}

.logo {
	height: 50px;
	width: 50px;
	padding: 10px;
	float: left;
}

.tabContainer {
	text-align: center;
	width: 95%;
}

.tab {
	color: gray;
	cursor: pointer;
	display: inline-block;
	margin: 20px;
	font-size: 20px;
}

.activeTab {
	color: white;
	border-bottom: 2px solid white;
	cursor: pointer;
	display: inline-block;
	margin: 25px;
	font-size: 20px;
}
